class Roller {

  constructor(filter, maritalStatus) {
    this.filter = filter
    this.maritalStatusEl = document.querySelector(`.resultsGroup[data-group="marital-status"]`)
    this.childrenEl = document.querySelector(`.resultsGroup[data-group="children"]`)
    this.primaryCareerEl = document.querySelector(`.resultsGroup[data-group="primary-career"]`)
    this.secondaryCareerEl = document.querySelector(`.resultsGroup[data-group="secondary-career"]`)
    this.genGoalEl = document.querySelector(`.resultsGroup[data-group="gengoal"]`)
    this.miscFunEl = document.querySelector(`.resultsGroup[data-group="miscfun"]`)
    this.rollAllListener()
    this.reRollListener()
  }

  rollAllListener() {
    const rollBtn = document.querySelector('#rollBtn')
    rollBtn.addEventListener('click', () => {
      this.showReRollButtons()
      this.emptyAllFields()
      let endpoint = 'api/roll';
      if (this.filter.packs.length > 0) {
        endpoint += "?exclude=" + this.filter.packs.join(',')
      }
      fetch(endpoint).then(res => res.json().then(data => {
        let workingSims = data.marital_status.working_sims
        this.setFields(this.maritalStatusEl, data.marital_status.title, data.marital_status.description)
        this.maritalStatusEl.setAttribute('data-workers', workingSims)
        this.setFields(this.primaryCareerEl, data.careers.primary.career[0].title, data.careers.primary.career[0].description)
        this.setFields(this.childrenEl, data.children.children)
        if (data.gengoal[0].title === "Midlife Crisis") {
          this.addMidlifeCrisis()
        }
        this.setFields(this.genGoalEl, data.gengoal[0].title, data.gengoal[0].description)
        if (data.miscfun[0].title === "Rainbow Brite") {
          this.addRainbowBrite()
        }
        this.setFields(this.miscFunEl, data.miscfun[0].title, data.miscfun[0].description)
        this.setSecondaryCareers(data)
      }))
    })
  }

  reRollListener() {
    document.addEventListener('click', ev => {
      let endpoint
      if (ev.target.classList.contains('resultsGroup__reroll')) {
        let group = ev.target.getAttribute('data-reroll')
        switch (group) {
          case 'marital-status':
            endpoint = 'api/maritalstatus';
            if (this.filter.packs.length > 0) {
              endpoint += "?exclude=" + this.filter.packs.join(',')
            }
            console.log(endpoint)
            fetch(endpoint).then(res => res.json().then(data => {
              console.log(data)
              this.setFields(this.maritalStatusEl, data.title, data.description)
              this.maritalStatusEl.setAttribute('data-workers', data.working_sims)
              // Since number of workers probably are different, we need to re-roll careers

              endpoint = `api/careers/${data.working_sims}`
              if (this.filter.packs.length > 0) {
                endpoint += "?exclude=" + this.filter.packs.join(',')
              }
              fetch(endpoint).then(res => res.json().then(data => {
                // Log for working sims
                // console.log(">-------->")
                // console.log(endpoint, data)
                this.setFields(this.primaryCareerEl, data.primary.career[0].title, data.primary.career[0].description)
                this.setSecondaryCareers(data)
              }))
            }))
            break;
          case 'children':
            endpoint = 'api/children';
            fetch(endpoint).then(res => res.json().then(data => {
              this.setFields(this.childrenEl, data.children)
            }))
            break;
          case 'primary-career':
            endpoint = 'api/careers/1'
            if (this.filter.packs.length > 0) {
              endpoint += "?exclude=" + this.filter.packs.join(',')
            }
            fetch(endpoint).then(res => res.json().then(data => {
              this.setFields(this.primaryCareerEl, data.primary.career[0].title, data.primary.career[0].description)
            }))
            break;
          case 'secondary-career':
            let workers = this.maritalStatusEl.getAttribute('data-workers')
            endpoint = `api/careers/${workers}`
            if (this.filter.packs.length > 0) {
              endpoint += "?exclude=" + this.filter.packs.join(',')
            }
            fetch(endpoint).then(res => res.json().then(data => {
              // console.log("Set Secondary Career")
              this.setSecondaryCareers(data)
            }))
            break;
          case 'gengoal':
            endpoint = 'api/gengoal'
            if (this.filter.packs.length > 0) {
              endpoint += "?exclude=" + this.filter.packs.join(',')
            }
            fetch(endpoint).then(res => res.json().then(data => {
              this.setFields(this.genGoalEl, data[0].title, data[0].description)
              if (data[0].title === "Midlife Crisis") {
                this.addMidlifeCrisis()
              }
            }))
            break;
          case 'miscfun':
            endpoint = 'api/miscfun'
            if (this.filter.packs.length > 0) {
              endpoint += "?exclude=" + this.filter.packs.join(',')
            }
            fetch(endpoint).then(res => res.json().then(data => {
              this.setFields(this.miscFunEl, data[0].title, data[0].description)
              if (data[0].title === "Rainbow Brite") {
                this.addRainbowBrite()
              }
            }))
            break;
        }
      }
    })
  }

  emptyAllFields() {
    this.emptyFields(this.maritalStatusEl)
    this.emptyFields(this.childrenEl)
    this.emptyFields(this.primaryCareerEl)
    this.emptySecondaryCareers()
    this.emptyFields(this.genGoalEl)
    this.emptyFields(this.miscFunEl)
  }

  // Methods for emptying each group
  emptyFields(el) {
    let result = el.querySelector('.resultsGroup__result')
    let description = el.querySelector('.resultsGroup__description')
    if (result) {
      result.innerHTML = ""
    }
    if (description) {
      description.innerHTML = ""
    }
  }

  emptySecondaryCareers() {
    let rows = this.secondaryCareerEl.querySelectorAll('.resultsGroup__row:not(.resultsGroup__row--template)')
    // console.log(rows)
    rows.forEach(row => {
      row.remove()
    })
  }

  // Set new values
  setFields(el, title, description = null) {
    if (title) {
      el.querySelector('.resultsGroup__result').innerHTML = title
    }
    if (description) {
      el.querySelector('.resultsGroup__result').classList.add('resultsGroup__result--hasDescription')
      el.querySelector('.resultsGroup__description').innerHTML = description
      el.querySelector('.resultsGroup__description').style.maxHeight = "0px"
    } else {
      el.querySelector('.resultsGroup__result').classList.remove('resultsGroup__result--hasDescription')
      el.querySelector('.resultsGroup__description').innerHTML = ""
    }
  }

  setSecondaryCareers(data) {
    this.emptySecondaryCareers()
    let secondaries = data.secondaries
    if (!secondaries && data.careers) {
      secondaries = data.careers.secondaries
    } else {
      secondaries === null
    }
    if (secondaries && secondaries.length > 0) {
      if (secondaries.length === 1) {
        this.secondaryCareerEl.querySelector('.resultsGroup__title').innerHTML = "Secondary Career"
      } else {
        this.secondaryCareerEl.querySelector('.resultsGroup__title').innerHTML = "Secondary Careers"
      }
      this.secondaryCareerEl.classList.remove('resultsGroup--hidden')
      secondaries.forEach(career => {
        let currentEl = null;
        let newEl = this.secondaryCareerEl.querySelector('.resultsGroup__row').cloneNode(true)
        newEl.classList.remove('resultsGroup__row--template')
        this.secondaryCareerEl.appendChild(newEl)
        currentEl = newEl
        this.setFields(currentEl, career.career[0].title, career.career[0].description)
      })
    } else {
      // console.log(this.secondaryCareerEl)
      this.secondaryCareerEl.classList.add('resultsGroup--hidden')
      this.emptySecondaryCareers()
    }
  }

  addMidlifeCrisis() {
    let endpoint = "api/midlifecrisis"
    if (this.filter.packs.length > 0) {
      endpoint += "?exclude=" + this.filter.packs.join(',')
    }
    fetch(endpoint).then(res => res.json().then(data => {
      this.genGoalEl.querySelector('.resultsGroup__description').insertAdjacentHTML(
          'beforeend', '<h3 class="resultsGroup__midlifecrisis">Your rolls:</h3>'
        )
        data.forEach(item => {
          this.genGoalEl.querySelector('.resultsGroup__description').insertAdjacentHTML('beforeend',
          `<article><h4>${item.title}</h4><p>${item.description}</p></article>`)
        })
    }))
  }

  addRainbowBrite() {
    let endpoint = "api/color"
    fetch(endpoint).then(res => res.json().then(data => {
      this.miscFunEl.querySelector('.resultsGroup__description').insertAdjacentHTML(
          'beforeend', '<p class="resultsGroup__rainbowbrite">You rolled the color ' + data[0].title.toLowerCase() + "</p>"
        )
    }))
  }

  // Helper
  showReRollButtons() {
    const buttons = document.querySelectorAll('.resultsGroup__reroll')
    buttons.forEach(button => {
      button.classList.remove('resultsGroup__reroll--disabled')
    })
  }
}

export default Roller