class Filter {

  constructor() {
    this.button = document.querySelector('#filterBtn')
    this.packs = []
    this.filterContainer = document.querySelector('.filter')
    this.filterButtonListener()
    this.filterListener()
  }

  filterButtonListener() {
    this.button.addEventListener('click', () => {
      if (this.button.classList.contains('open')) {
        this.filterContainer.style.maxHeight = "0px"
      } else {
        this.filterContainer.style.maxHeight = this.filterContainer.scrollHeight + "px"
      }
      this.button.classList.toggle('open')
    })
  }

  filterListener() {
    this.filterContainer.addEventListener('click', ev => {
      if (ev.target.classList.contains('filterItem')) {
        ev.target.classList.toggle('filterItem--disabled')
        let pack = ev.target.dataset.pack
        if (this.packs.includes(pack)) {
          this.packs.splice(this.packs.indexOf(pack), 1)
        } else {
          this.packs.push(pack)
        }
      }
      // "Disable all"-button
      if (ev.target.classList.contains('disable-all')) {
        let type = ev.target.getAttribute('data-type')
        let parent = document.querySelector(`.filterGroup__grid[data-type=${type}]`)
        let items = parent.querySelectorAll('.filterItem')
        items.forEach(item => {
          let pack = item.dataset.pack
          if (!this.packs.includes(pack)) {
            item.classList.add('filterItem--disabled')
            this.packs.push(pack)
          }
        })
      }
      // "Enable all"-button
      if (ev.target.classList.contains('enable-all')) {
        let type = ev.target.getAttribute('data-type')
        let parent = document.querySelector(`.filterGroup__grid[data-type=${type}]`)
        let items = parent.querySelectorAll('.filterItem')
        items.forEach(item => {
          let pack = item.dataset.pack
          if (this.packs.includes(pack)) {
            item.classList.remove('filterItem--disabled')
            this.packs.splice(this.packs.indexOf(pack), 1)
          }
        })
      }
    })
  }

}

export default Filter