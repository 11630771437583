class Description {
  
  constructor() {
    this.descriptionHandler()
  }
  
  descriptionHandler() {
    document.addEventListener('click', ev => {
      if (ev.target.classList.contains('resultsGroup__result--hasDescription')) {
        let sibling = ev.target.nextElementSibling
        if (sibling.style.maxHeight === "0px") {
          sibling.style.maxHeight = sibling.scrollHeight + "px"
        } else {
          sibling.style.maxHeight = "0px"
        }
      }
    })
  }

}

export default Description