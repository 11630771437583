import Filter from './modules/Filter'
import Roller from './modules/Roller'
import Description from './modules/Description'


document.addEventListener('DOMContentLoaded', () => {
  // Add all your JS modules here:
  const filter = new Filter()
  const roller = new Roller(filter)
  const description = new Description()
})